<template>
  <div>
    <custom-label-18 align="center" :label="label" grey/>
    <vue-apex-chart
      v-if="hasData"
      :key="componentKey"
      align="center"
      ref="customChart"
      type="donut"
      height="230"
      :options="chartOptions"
      :series="series"
    />
    <p v-else align="center" class="red--text caption mt-4">Nenhum dado encontrado</p>
  </div>
</template>

<script>
import CustomLabel18 from '../../texts/CustomLabel18.vue';
export default {
  components: { CustomLabel18 },
  name: "RadialBar",
  props: {
    series:{
      type: Array,
      required:true,
    }, 
    categories:{}, 
    min:{}, 
    max:{}, 
    tickAmount:{},
    label:{},
    cultivarProductivity: {
      type: Number
    }
  },
  data: () => ({
    componentKey: 0
  }),
  computed:{
    chartOptions(){
      let productivity = this.cultivarProductivity;

      return{
        chart: {
          height: 230,
          type: 'donut',
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show:false,
                },
                value: {
                  fontSize: '16px',
                  fontWeight: 600,
                  offsetY: 0,
                  formatter:(value)=>{
                    if (!Array.isArray(value)) {
                      return value + "%";
                    } else {
                      this.componentKey += 1;
                    }
                  }
                },
                total: {
                  show: true,
                  formatter: function (w) {
                    const data1 = w.globals.seriesTotals[0];
                    const data2 = w.globals.seriesTotals[1];

                    if (data1 !== 0 || data2 !== 0) {
                      return [parseFloat(productivity.toFixed(1)), "sc/ha"];
                    }
                  }
                }
              }
            }
          }
        },

        legend: {
          position: 'bottom',
        },

        tooltip:{
          enabled:false,
        },
        
        dataLabels:{
          enabled:false,
        },

        labels: this.categories,

        colors:['#689f38', '#febc00'],
      }
    },
    hasData(){
      if(this.series.length === 0)
        return false;
      
      return true;
    }
  },
  methods:{
    updateChart(){      
    }
  },
  mounted() {
    this.updateChart();
  },
};
</script>
<template>
  <div>
    <toolbar :toolbarName="'Painel de Produtividade'" :openDetails="true" />

    <search-bar :noSecondaryInput="false" :noTertiaryInput="false" :noQuaternaryInput="false" :hasTextInput="false"
      :hasClearFilters="false" :hasActionButton="true" actionButtonLabel="Buscar" @externalAction="searchCultivars()"
      :value="search">
      <simple-select v-if="authoritySlug !== 'producer'" :items="producers" itemText="user.name" itemValue="id"
        v-model="selectedProducer" @input="getFarms" class="mr-4" placeholder="Proprietário" height="auto" />

      <template slot="tertiary-input">
        <simple-select :items="farms" itemText="name" itemValue="id" v-model="selectedFarm" @input="getHarvests"
          class="mr-4" placeholder="Fazenda" height="auto" />
      </template>

      <template slot="quaternary-input">
        <simple-select :items="harvests" itemText="name" itemValue="item" v-model="selectedHarvest" class="mr-4"
          placeholder="Safra" height="auto" />
      </template>
    </search-bar>

    <!-- Painel do Cultivares -->
    <div v-if="productivitySearched">

      <v-card class="mb-2 rounded-xl">
        <v-card-title class="yellow-border">
          <v-container>
            <v-row>
              <v-col>
                <custom-card-title class="grey--text text--darken-2"
                  label="Histórico de Produtividade"></custom-card-title>
              </v-col>
              <!-- <v-col class="px-1 py-1 py-md-2" cols="12" md="3">
                <simple-select :items="cultures" itemText="name" itemValue="id" v-model="selectedHistoryHarvests"
                  @input="() => { }" placeholder="Selecione as culturas" height="auto" />
              </v-col> -->
              <v-col class="px-1 py-1 py-md-2 d-flex align-items-center" cols="6" md="3">
                <simple-select :items="harvests" itemText="name" multiple itemValue="id" v-model="selectedHistoryHarvests"
                  @input="getProductivityHistory" placeholder="Selecione as safras" height="auto" />
              </v-col>
              <v-col class="d-flex justify-center py-1 py-md-2" cols="6" md="1">
                <text-button color="black" noMargin @callAction="openFilterModal()" label="Filtrar" />
              </v-col>
            </v-row>
            <v-row v-if="historyLoading">
              <v-container class="d-flex justify-center align-items-center">
                <v-progress-circular :size="70" :width="7" indeterminate color="primary" />
              </v-container>
            </v-row>
            <v-row v-else>
              <v-col>
                <apex-chart ref="barChart" :series="historySeries" width="800" :categories="historyCategories" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
      </v-card>



      <productivity-graph v-if="false" :cultures="cultures" :farm="selectedFarm" :harvest="selectedHarvest">
      </productivity-graph>

      <v-card class="mb-2 rounded-xl">
        <v-card-title class="yellow-border">
          <v-container>
            <v-row>
              <custom-card-title class="grey--text text--darken-2" label="Painel do Cultivares" />
            </v-row>
            <v-row align="center" justify="center" ref="chartRow">
              <apex-chart ref="barChart" :series="barSeries" width="800" :categories="barCategories" />
            </v-row>
          </v-container>
        </v-card-title>
      </v-card>

      <!-- Distribuição dos cultivares -->
      <v-card class="mb-12 rounded-xl">
        <v-card-title class="yellow-border">
          <v-container>
            <v-row>
              <v-col cols="12" md="4" :sm="false">
                <custom-card-title align="start" class="grey--text text--darken-2" label="Distribuição de Cultivares" />
              </v-col>
              <v-col>
                <v-row class="pa-0">
                  <v-col class="px-1 py-1 py-md-2" cols="12" md="4">
                    <simple-select :items="cultures" itemText="name" itemValue="id" v-model="selectedCulture"
                      @input="getCultivars" placeholder="Cultura" height="auto" />
                  </v-col>
                  <v-col class="px-1 py-1 py-md-2" cols="12" md="5">
                    <simple-select :items="cultivars" itemText="cultivar.name" itemValue="cultivar"
                      @input="getDistributionHarvest" v-model="selectedCultivar" placeholder="Cultivar" height="auto" />
                  </v-col>
                  <v-col class="px-1 py-1 py-md-2" cols="12" md="3">
                    <simple-select :items="harvestsDistribution" itemText="name" itemValue="item"
                      v-model="selectedDistributionHarvest" @input="searchDistribution" placeholder="Safra"
                      height="auto" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="distributionSearched" class="d-md-flex justify-center">
              <v-col cols="12" md="5" style="margin: auto">
                <radial-bar label="Minha Fazenda" :series="radialSeries.farm" :categories="radialCategories"
                  :cultivarProductivity="farmCultivarPanel ? farmCultivarPanel.average_productivity : 0" />
              </v-col>
              <v-col>
                <v-row>
                  <v-col cols="12" md="4">
                    <radial-bar label="Brasil" :series="radialSeries.national" :categories="radialCategories"
                      :cultivarProductivity="nationalCultivarPanel ? nationalCultivarPanel.average_productivity : 0" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <radial-bar label="Região" :series="radialSeries.regional" :categories="radialCategories"
                      :cultivarProductivity="regionalCultivarPanel ? regionalCultivarPanel.average_productivity : 0" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <radial-bar label="Estado" :series="radialSeries.state" :categories="radialCategories"
                      :cultivarProductivity="stateCultivarPanel ? stateCultivarPanel.average_productivity : 0" />
                  </v-col>
                </v-row>
              </v-col>
            </div>
          </v-container>
        </v-card-title>
      </v-card>
    </div>

    <v-dialog v-model="filterModal" scrollable transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="filterModal = false" class="mx-5">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Filtros</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-12">
          <v-container>
            <span class="font-weight-bold font-18 text">Sementeiro</span>
            <v-checkbox v-model="historyFilters.is_sower" :value="true" label="Sim" />
            <v-checkbox v-model="historyFilters.is_sower" :value="false" label="Não" />
            <span class="font-weight-bold font-18 text">Área</span>
            <v-checkbox v-model="historyFilters.farm_size" value="0 a 500" label="0 - 500ha" />
            <v-checkbox v-model="historyFilters.farm_size" value="500 a 1000" label="500 - 1000ha" />
            <v-checkbox v-model="historyFilters.farm_size" value="1000 a 3000" label="1000 - 3000ha" />
            <v-checkbox v-model="historyFilters.farm_size" value="acima de 3000" label="Acima de 3000" />
            <span class="font-weight-bold font-18 text">Irrigação</span>
            <v-checkbox v-model="historyFilters.irrigation" value="Sem irrigação" label="Sem irrigação" />
            <v-checkbox v-model="historyFilters.irrigation" value="Sim, menos de 50% da área" label="< 50% da área irrigada" />
            <v-checkbox v-model="historyFilters.irrigation" value="Sim, mais de 50% da área" label="> 50% da área irrigada" />
            <span class="font-weight-bold font-18 text">Tipo de área</span>
            <v-checkbox v-model="historyFilters.area_type" value="1" label="Própria" />
            <v-checkbox v-model="historyFilters.area_type" value="2" label="Arrendada" />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="() => {
            filterModal = false;
            getProductivityHistory();
          }">Salvar</v-btn>
          <v-btn color="error" text @click="filterModal = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//Components
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import Toolbar from "@/components/crud/Toolbar.vue";
import CustomCardTitle from "@/components/texts/CustomCardTitle.vue";
import ApexChart from "@/components/graphs/apex/ApexChart.vue";
import RadialBar from "@/components/graphs/radialBar/RadialBar.vue";
import ProductivityGraph from "./ProductivityGraph.vue";
import TextButton from "@/components/buttons/TextButton";
// import PrimaryButton from "@/components/buttons/PrimaryButton";

//Domains and services
import Productivity from "@/domain/productivity/productivity";
import Producer from "@/domain/producers/producers";
import PlotRecord from "@/domain/records/plot-records/plot-records";
import Cultivars from "@/domain/cultivars/cultivars";
import Cultures from "@/domain/cultures/cultures";
import Distribution from "@/domain/distribution/distribution";

import { getItem } from "@/services/local-storage.service";

export default {
  name: "ProductivityPanel",

  components: {
    SearchBar,
    SimpleSelect,
    Toolbar,
    CustomCardTitle,
    ApexChart,
    RadialBar,
    ProductivityGraph,
    TextButton,
    // PrimaryButton,
  },

  data: function () {
    return {
      //Services
      plotsService: null,
      productivityService: null,
      producersService: null,
      cultivarsService: null,
      culturesService: null,
      distributionService: null,

      //Data
      harvests: [],
      harvestsDistribution: [],
      plots: {},
      producers: [],
      farms: [],
      AS: {},
      cultures: [],
      cultivars: [],
      producer_state: {},
      authoritySlug: '',
      farmCultivarPanel: [],
      nationalCultivarPanel: [],
      regionalCultivarPanel: [],
      stateCultivarPanel: [],

      //Results
      farmProductivity: {},
      nationalProductivity: {},
      regionalProductivity: {},
      stateProductivity: {},

      //Selected Filters
      selectedProducer: null,
      selectedFarm: null,
      selectedHarvest: null,
      selectedCulture: null,
      selectedCultivar: null,
      selectedDistributionHarvest: null,
      search: {},

      //Graphs config
      productivitySearched: false,
      distributionSearched: false,
      barSeries: [],
      barCategories: [],

      // History data
      historySeries: [],
      historyCategories: [],
      selectedHistoryHarvests: [],
      historyData: {},
      historyLoading: false,
      historyFilters: {
        is_sower: [],
        farm_size: [],
        area_type: [],
        irrigation: [],
      },

      filterModal: false,

      radialSeries: [],
      radialCategories: [],
    };
  },

  computed: {},

  methods: {
    initialize() {
      const loader = this.$loading.show();

      this.productivityService = Productivity;
      this.plotsService = PlotRecord;
      this.producersService = Producer;
      this.cultivarsService = Cultivars;
      this.culturesService = Cultures;
      this.distributionService = Distribution;

      this.authoritySlug = getItem("authoritySlug");

      this.getProcucers();
      this.getCultures();

      loader.hide();
    },

    //API data getters
    async getProcucers() {
      await this.plotsService.list().then((res) => {
        this.plots = res.data;
        this.plots.forEach((record) => {
          this.producers.push(record.producer);
        });
      });

      if (this.authoritySlug === "producer") {
        this.selectedProducer = Number(getItem("producerId"));
        this.getFarms();
      }
    },

    getHarvests() {
      this.harvests = [];
      this.selectedHarvest = null;

      this.farms.forEach((farm) => {
        if (farm.id === this.selectedFarm)
          this.producer_state = farm.state_id
      })

      this.plots.forEach((plot) => {
        if (plot.farm_id === this.selectedFarm)
          this.harvests.push(plot.harvest);
      });
    },

    getFarms() {
      this.farms = [];
      this.selectedFarm = null;
      this.selectedHarvest = null;
      let userId = "";

      this.producers.forEach((producer) => {
        if (this.selectedProducer === producer.id) userId = producer.user_id;
      });

      this.producersService.getProducerByUserId(userId).then((producer) => {
        this.AS = producer.data.responsible_success_advisor;
      });

      this.plots.forEach((plot) => {
        if (plot.producer.id === this.selectedProducer)
          this.farms.push(plot.farm);
      });
    },

    getProductivitys() {
      const loader = this.$loading.show();
      this.farmProductivity = {};
      this.nationalProductivity = {};

      //Produtividade da fazenda
      this.productivityService
        .getProductivityByFarm(this.selectedFarm, this.selectedHarvest)
        .then((res) => {
          this.farmProductivity = res.data.data;
          let serie = [];

          this.farmProductivity.forEach((cultivar) => {
            serie.push(cultivar.average_productivity);
            this.barCategories.push(cultivar.cultivar);
          });
          this.barSeries.push({
            name: "Fazenda",
            data: serie,
          });
        })
        .then(() => {
          //Produtividade Nacional
          this.productivityService
            .getNationalProductivity(this.selectedHarvest)
            .then((res) => {
              this.nationalProductivity = res.data.data;
              let serie = [];

              this.nationalProductivity.forEach((cultivar) => {
                const nameIndex = this.barCategories.indexOf(cultivar.cultivar);
                serie[nameIndex] = cultivar.average_productivity;
              });
              this.barSeries.push({
                name: "Nacional",
                data: serie,
              });
            })
            .then(() => {
              //Produtividade Regional
              this.productivityService
                .getRegionalProductivity(this.AS.id, this.selectedHarvest)
                .then((res) => {
                  this.nationalProductivity = res.data.data;
                  let serie = [];

                  this.nationalProductivity.forEach((cultivar) => {
                    const nameIndex = this.barCategories.indexOf(
                      cultivar.cultivar
                    );
                    serie[nameIndex] = cultivar.average_productivity;
                  });
                  this.barSeries.push({
                    name: "Regional",
                    data: serie,
                  });
                })
                .then(() => {
                  this.updateCharts();
                  loader.hide();
                });
            });
        })
        .catch(() => {
          loader.hide();
        });
    },

    getProductivityHistory() {
      this.historyLoading = true;
      const distributions = [
        { name: this.farms.find(farm => farm.id === this.selectedFarm).name, slug: "farm" },
        { name: "Nacional", slug: "national" },
        { name: "Regional", slug: "regional" },
      ]
      this.cultures.forEach(culture => {
        this.historyData[culture.name] = distributions.map(
          distribution => ({ name: distribution.name, data: [] })
        )
      })
      const harvests = this.selectedHistoryHarvests.length > 0 ? this.selectedHistoryHarvests : this.harvests;


      this.plotsService.getHistory(
        this.selectedFarm,
        harvests,
        this.historyFilters
      ).then(({ data }) => {
        distributions.forEach((distribution, index) => {
          this.cultures.forEach(culture => {
            Object.keys(data[distribution.slug]).map(year => {
              this.historyData[culture.name][index].data
                .push(data[distribution.slug][year][culture.name] || 0)
            })
          })
        })
      }).finally(() => {
        this.historyLoading = false;
      })

      this.historySeries = this.historyData["Soja"];
      this.historyCategories = harvests;
    },

    getProductivitysByCultivar() {
      const loader = this.$loading.show();
      this.farmCultivarPanel = [];
      this.nationalCultivarPanel = [];
      this.regionalCultivarPanel = [];
      this.stateCultivarPanel = [];

      //Produtividade da fazenda
      this.productivityService
        .getProductivityByFarm(this.selectedFarm, this.selectedDistributionHarvest, this.selectedCultivar.id)
        .then((res) => {
          const response = res.data.data;
          this.farmCultivarPanel = response[0];
        });

      //Produtividade Nacional
      this.productivityService
        .getNationalProductivity(this.selectedDistributionHarvest, this.selectedCultivar.id)
        .then((res) => {
          const response = res.data.data;
          this.nationalCultivarPanel = response[0];
        });

      //Produtividade Regional
      this.productivityService
        .getRegionalProductivity(this.AS.id, this.selectedDistributionHarvest, this.selectedCultivar.id)
        .then((res) => {
          const response = res.data.data;
          this.regionalCultivarPanel = response[0];
        });

      //Produtividade Estadual
      this.productivityService
        .getStateProductivity(this.producer_state, this.selectedDistributionHarvest, this.selectedCultivar.id)
        .then((res) => {
          const response = res.data.data;
          this.stateCultivarPanel = response[0];
        });

      loader.hide();

      this.getDistributions();
    },

    getCultivars() {
      this.selectedDistributionHarvest = null;
      this.cultivarsService
        .getFarmCulture(this.selectedFarm, this.selectedCulture)
        .then((res) => {
          this.cultivars = res;
        });
    },

    getCultures() {
      this.culturesService
        .all()
        .then((result) => {
          this.cultures = result.data;
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    getDistributions() {
      let loader = this.$loading.show();
      this.radialSeries = {
        farm: [],
        national: [],
        regional: [],
        state: [],
      };
      this.radialCategories = [this.selectedCultivar.name, "Outros"];

      this.distributionService
        .getDistributionByFarm(
          this.selectedFarm,
          this.selectedDistributionHarvest,
          this.selectedCultivar.id
        )
        .then((res) => {
          const data = res.data.data;

          let sowingAreaPercentage = data.sowing_area_percentage
            ? data.sowing_area_percentage.toFixed(1)
            : data.sowing_area_percentage;

          let difference = data.difference
            ? data.difference.toFixed(1)
            : data.difference;

          this.radialSeries.farm.push(Number(sowingAreaPercentage));
          this.radialSeries.farm.push(Number(difference));

          this.distributionService
            .getNationalDistribution(
              this.selectedDistributionHarvest,
              this.selectedCultivar.id
            )
            .then((res) => {
              const data = res.data.data;

              this.radialSeries.national.push(
                Number(data.sowing_area_percentage.toFixed(1))
              );
              this.radialSeries.national.push(
                Number(data.difference.toFixed(1))
              );
            });

          this.distributionService
            .getRegionalDistribution(
              this.AS.id,
              this.selectedDistributionHarvest,
              this.selectedCultivar.id
            )
            .then((res) => {
              const data = res.data.data;

              this.radialSeries.regional.push(
                Number(data.sowing_area_percentage.toFixed(1))
              );
              this.radialSeries.regional.push(
                Number(data.difference.toFixed(1))
              );
            });

          this.distributionService
            .getStateDistribution(
              this.producer_state,
              this.selectedDistributionHarvest,
              this.selectedCultivar.id
            )
            .then((res) => {
              const data = res.data.data;

              this.radialSeries.state.push(
                Number(data.sowing_area_percentage.toFixed(1))
              );
              this.radialSeries.state.push(Number(data.difference.toFixed(1)));
            });
        })
        .then(() => {
          loader.hide();
        });
    },

    getDistributionHarvest() {
      this.selectedDistributionHarvest = null;
      this.harvestsDistribution = this.harvests;
    },

    searchDistribution() {
      this.distributionSearched = true;
      this.radialSeries = [];
      this.radialCategories = [];
      this.getProductivitysByCultivar();
    },

    searchCultivars() {
      this.barSeries = [];
      this.barCategories = [];
      this.selectedCulture = null;
      this.selectedCultivar = null;
      this.selectedDistributionHarvest = null;
      this.distributionSearched = false;

      if (this.selectedProducer && this.selectedFarm && this.selectedHarvest) {
        this.productivitySearched = true;
        this.getProductivitys();
        this.getProductivityHistory();
      } else {
        this.$toasted.show("Você deve selecionar os filtros", {
          type: "error",
        });
      }
    },

    openFilterModal() {
      this.filterModal = true;
    },

    updateCharts() {
      this.$refs.barChart.updateChart();
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>
import http from "@/services/http.service";

const apiUrl = "/distribution";

class DistributionService {
  static getDistributionByFarm(farmID, harvest, cultivarId) {
    return http.get(
      `${apiUrl}/farms/${farmID}/harvests/${harvest}/cultivars/${cultivarId}`
    );
  }

  static getNationalDistribution(harvest, cultivarId) {
    return http.get(
      `${apiUrl}/national/harvests/${harvest}/cultivars/${cultivarId}`
    );
  }

  static getRegionalDistribution(asID, harvest, cultivarId) {
    return http.get(
      `${apiUrl}/regional/as/${asID}/harvests/${harvest}/cultivars/${cultivarId}`
    );
  }

  static getStateDistribution(stateId, harvest, cultivarId) {
    return http.get(
      `${apiUrl}/state/${stateId}/harvests/${harvest}/cultivars/${cultivarId}`
    );
  }
}

export default DistributionService;

import http from "@/services/http.service";

const apiUrl = "/productivity";

class ProductivityService {
  static getProductivityByFarm(farmID, harvest, cultivarId) {
    if (cultivarId)
      return http.get(
        `${apiUrl}/farms/${farmID}/harvests/${harvest}/cultivars/${cultivarId}`
      );
    else
      return http.get(
        `${apiUrl}/farms/${farmID}/harvests/${harvest}/cultivars`
      );
  }

  static getNationalProductivity(harvest, cultivarId) {
    if (cultivarId)
      return http.get(
        `${apiUrl}/national/harvests/${harvest}/cultivars/${cultivarId}`
      );
    else return http.get(`${apiUrl}/national/harvests/${harvest}/cultivars`);
  }

  static getRegionalProductivity(asID, harvest, cultivarId) {
    if (cultivarId)
      return http.get(
        `${apiUrl}/regional/as/${asID}/harvests/${harvest}/cultivars/${cultivarId}`
      );
    else
      return http.get(
        `${apiUrl}/regional/as/${asID}/harvests/${harvest}/cultivars`
      );
  }

  static getStateProductivity(stateID, harvest, cultivarId) {
    if (cultivarId)
      return http.get(
        `${apiUrl}/state/${stateID}/harvests/${harvest}/cultivars/${cultivarId}`
      );
    else
      return http.get(
        `${apiUrl}/state/${stateID}/harvests/${harvest}/cultivars`
      );
  }
}

export default ProductivityService;

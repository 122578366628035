import  ProductivityService from "./productivity.service";

class  Productivity {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Productivity.build(payload),
        });
    }

    static build(
        {
            id = '',
            producer_id = '',
            user_id = '',
            user = {}
        }
    ) {
        return {
            id,
            producer_id,
            user_id,
            user
        }
    }

    static newData(payload) {
        return new Productivity(payload);
    }

    static getProductivityByFarm(farmID,harvest,cultivarId){
        return new Promise((resolve, reject) => {
            ProductivityService
                .getProductivityByFarm(farmID,harvest, cultivarId)
                .then(
                    success => {
                        const productivity = success;
                        
                        resolve(productivity);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getNationalProductivity(harvest,cultivarId) {
        return new Promise((resolve, reject) => {
            ProductivityService
                .getNationalProductivity(harvest, cultivarId)
                .then(
                    success => {
                        const productivity = success;
                        
                        resolve(productivity);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getRegionalProductivity(asID,harvest,cultivarId) {
        return new Promise((resolve, reject) => {
            ProductivityService
                .getRegionalProductivity(asID,harvest,cultivarId)
                .then(
                    success => {
                        const productivity = success;
                        
                        resolve(productivity);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getStateProductivity(stateID,harvest,cultivarId) {
        return new Promise((resolve, reject) => {
            ProductivityService
                .getStateProductivity(stateID,harvest,cultivarId)
                .then(
                    success => {
                        const productivity = success;
                        
                        resolve(productivity);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }
}

export default Productivity;

import  DistributionService from "./distribution.service";

class  Distribution {
    constructor(payload = {}) {
        Object.assign(this, {
            ...Distribution.build(payload),
        });
    }

    static build(
        {
            id = '',
            producer_id = '',
            user_id = '',
            user = {}
        }
    ) {
        return {
            id,
            producer_id,
            user_id,
            user
        }
    }

    static getDistributionByFarm(farmID,harvest,cultivarId){
        return new Promise((resolve, reject) => {
            DistributionService
                .getDistributionByFarm(farmID,harvest, cultivarId)
                .then(
                    success => {
                        const distribution = success;
                        
                        resolve(distribution);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getNationalDistribution(harvest,cultivarId) {
        return new Promise((resolve, reject) => {
            DistributionService
                .getNationalDistribution(harvest, cultivarId)
                .then(
                    success => {
                        const distribution = success;
                        
                        resolve(distribution);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getRegionalDistribution(asID,harvest,cultivarId) {
        return new Promise((resolve, reject) => {
            DistributionService
                .getRegionalDistribution(asID,harvest,cultivarId)
                .then(
                    success => {
                        const distribution = success;
                        
                        resolve(distribution);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getStateDistribution(stateId, harvest, cultivarId) {
        return new Promise((resolve, reject) => {
            DistributionService
                .getStateDistribution(stateId,harvest,cultivarId)
                .then(
                    success => {
                        const distribution = success;
                        
                        resolve(distribution);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }
}

export default Distribution;
